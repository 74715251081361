import { graphql } from "gatsby";
import parse from "html-react-parser";
import React from "react";
import Container from "~components/common/Container";
import Layout from "~components/layouts/Layout";

export default function Page({ data, location }) {
	return (
		<Layout
			location={location}
			title="Impressum von Lautenschlager Marketing & Entwicklung"
			desc="Unsere Daten: Lautenschlager Marketing & Enwticklung. Einbecker Str. 3, 34123 Kassel. anfrage@lautenschlager.de"
			imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
			noIndex
		>
			<Container className="mx-auto my-8 max-w-screen-sm md:my-16">
				<div className="prose prose-lg">
					<div>{parse(data.rechtliches.text)}</div>
				</div>
			</Container>
		</Layout>
	);
}

export const query = graphql`
	query {
		rechtliches(titel: { eq: "Impressum" }) {
			text
		}
		seoImage: file(relativePath: { eq: "pages/datenschutz/datenschutz_seo-image.jpg" }) {
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitter: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
